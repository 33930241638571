<style lang="scss" scoped>
.app {
  text-align: left;
  .m-input-row {
    padding: 4px 0;
  }
}
</style>

<template>
  <el-dialog
    title="批量加入专题"
    width="70%"
    :visible.sync="ishow"
    :destroy-on-close="true"
    :close-on-click-modal="false"
    :append-to-body="true"
    @closed="close"
  >
    <div class="app" ref="app">
      <el-form ref="form" size="mini" :model="form" label-width="100px">
        <el-form-item label-width="10px">
          注意：定时任务可以在<span style="color: #f00"
            >【商品库 - 商品导入】</span
          >中查看，每次最多导入<span style="color: #f00">10000个商品</span
          >到专题
        </el-form-item>

        <el-form-item
          prop="topic_id"
          :rules="rules.topic_id"
          label="选择专题："
        >
          <el-select
            v-model="form.topic_id"
            size="mini"
            multiple=""
            filterable
            placeholder="请输入关键词"
          >
            <el-option
              v-for="item in itemTopicOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="单选：" prop="taskName" :rules="rules.taskName">
          <el-radio-group v-model="form.importType" :disabled="!!defData.id">
            <div>
              <el-radio :label="0"
                ><span style="font-size: 12px">单次导入</span></el-radio
              >
            </div>
            <div style="height: 30px">&nbsp; &nbsp;</div>
            <div>
              <el-radio :label="1"
                ><span style="font-size: 12px">定时导入</span></el-radio
              >
              <el-input
                v-model="form.taskName"
                style="width: 150px"
                placeholder="请输入任务名称"
              ></el-input>
              <span
                style="height: 10px; width: 10px; display: inline-block"
              ></span>
              <el-input
                v-model="form.udateNum"
                style="width: 120px"
                placeholder="请输入更新频率"
              ></el-input>
              <span style="font-size: 12px">&nbsp;&nbsp;分钟/次</span>
            </div>
          </el-radio-group>
        </el-form-item>

        <el-form-item>
          <el-button @click="ishow = false">取消</el-button>
          <el-button type="primary" @click="save">保存</el-button>
        </el-form-item>
      </el-form>
    </div>
  </el-dialog>
</template>


<script>
import * as api from "@/config/api";
import { Loading } from "element-ui";
export default {
  name: "joinTopic",
  props: {
    params: Object,
    defData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    const checkFloatNumber = (rule, value, callback, errorMsg) => {
      const r = /^[0-9]([0-9]+)?$/;
      if (value && !r.test(value)) {
        callback(new Error(errorMsg));
      } else {
        callback();
      }
    };
    console.log(this.defData);
    return {
      ishow: true,
      topicHash: {},
      form: {
        topic_id: this.defData.topic_id || [],
        importType: this.defData.importType || 1,
        taskName: this.defData.taskName || "",
        udateNum: this.defData.udateNum || "",
      },
      rules: {
        topic_id: [
          {
            validator(rule, value, callback) {
              if (!value || value.length == 0) {
                callback(new Error("请选择专题"));
                return;
              }
              callback();
            },
          },
        ],
        taskName: [
          {
            validator: (rule, value, callback) => {
              if (this.form.importType == 1) {
                if (value == "") {
                  callback(new Error("请输入任务名称"));
                  return;
                }
                if (this.form.udateNum == "") {
                  callback(new Error("请输入更新频率"));
                  return;
                }
                checkFloatNumber(
                  rule,
                  this.form.udateNum,
                  callback,
                  "请输入正确更新频率"
                );

                return;
              }
              callback();
            },
          },
        ],
      },
    };
  },

  created() {
    this.queryItemTopicOptions("");
  },

  computed: {
    itemTopicOptions() {
      return Object.values(this.topicHash);
    },
  },

  methods: {
    close() {
      this.$emit("close");
    },
    save() {
      this.$refs.form.validate((v) => {
        if (!v) {
          return;
        }

        this.saveTopic(this.form);
      });
    },
    // 批量加入专题
    saveTopic(data) {
      if (this.defData.id) {
        const params = Object.assign({
          id: this.defData.id,
          item_topic_id: data.topic_id,
          task_name: data.taskName,
          update_frequency: data.udateNum,
          import_type: data.importType + 1,
        });
        let loadingInstance = Loading.service({
          target: this.$refs.app,
          background: "rgba(0,0,0,.8)",
          spinner: "el-icon-loading",
        });
        api.updateImprotGoodTopic(params, (res) => {
          this.$message({
            message: res.msg,
            type: "success",
          });
          loadingInstance.close();
          this.ishow = false;
          this.$emit("close");
        });
        return;
      }
      const params = Object.assign(
        {
          item_topic_id: data.topic_id,
          task_name: data.taskName,
          update_frequency: data.udateNum,
          import_type: data.importType + 1,
        },
        this.params
      );
      let loadingInstance = Loading.service({
        target: this.$refs.app,
        background: "rgba(0,0,0,.8)",
        spinner: "el-icon-loading",
      });
      api.improtGoodTopic(params, (res) => {
        this.$message({
          message: res.msg,
          type: "success",
        });
        loadingInstance.close();
        this.ishow = false;
        this.$emit("close");
      });
    },
    queryItemTopicOptions(words) {
      api.queryItemTopicOptions(
        {
          keyword: words,
          pagesize: 100,
        },
        (res) => {
          const list = res.data.list;

          list.forEach((item) => {
            this.$set(this.topicHash, item.topic_id, {
              value: item.topic_id,
              label: item.topic_name,
            });
          });
        }
      );
    },
  },
};
</script>